import { render, staticRenderFns } from "./Qiu360ForResultForMobile.vue?vue&type=template&id=a20dc22e&scoped=true&"
import script from "./Qiu360ForResultForMobile.vue?vue&type=script&lang=js&"
export * from "./Qiu360ForResultForMobile.vue?vue&type=script&lang=js&"
import style0 from "./Qiu360ForResultForMobile.vue?vue&type=style&index=0&id=a20dc22e&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a20dc22e",
  null
  
)

export default component.exports