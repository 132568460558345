<template>
  <div>
    <div class="wrapper">
      <div class="title">
        {{index}}. {{ data.modelName }}
      </div>
      <div class="desc">
        {{ data.text }}
      </div>
      <div class="chart-wrapper" v-if="mode=='图形'">
          <div :label="chartTypeName(chart.imageType)" v-for="(chart,index) in this.jsonData.ruleOfInvoiceImageList"
             :key="index" >
          <ColumnChart :data="chart"
                       v-if="chart.imageType=='histogram'||chart.imageType=='twoHistogram'"
                       width="849px" height="435px"></ColumnChart>
          <LineChart :data="chart" v-if="chart.imageType=='brokenLine'" width="849px"
                     height="435px"></LineChart>
          <PieForResult :data="chart"
               v-if="chart.imageType=='annulus'"
               class="pie"></PieForResult>
          </div>


      </div>
      <div v-if="mode=='表格'">
        <GenericTableMobile :data="generalTableData">

        </GenericTableMobile>
      </div>

    </div>
  </div>
</template>

<script>

import LineChart from "../chats/LineChart";
import ColumnChart from "../chats/ColumnChart";
import PieForResult from "./PieForResult/PieForResult";
import GenericTableMobile
  from "../../../ResultEditor/components/InvoiceIndex/components/GenericTableMobile";

export default {
  name: "Qian10daMobile",
  components: {
    GenericTableMobile,
    PieForResult,
    LineChart,
    ColumnChart,
  },
  props: {
    data: Object,
    index:Number,
    mode:String
  },
  computed: {
    pdfData(){
      return this.jsonData;
    },
    jsonData() {
      return JSON.parse(this.data.jsonData)
    },
    //通用表格
    generalTableData() {
      if (!this.pdfData) {
        return null;
      }

      if (!this.pdfData.body) {
        return null;
      }

      const ret = [];

      for (let i = 0; i < this.pdfData.body.length; i++) {
        ret[i] = [];

        this.pdfData.header.forEach(key => {

          ret[i].push(this.pdfData.body[i][key])
        })

      }

      return {
        data: ret,
        labels: this.pdfData.header
      };
    },
    tips() {
      return this.pdfData.tips && this.pdfData.tips.map(v => {
        return {
          pos: [v.row != null ? v.row : -1, v.col != null ? v.col : -1],
          text: v.text
        }
      })
    },
    subTables() {
      return this.pdfData.subTables && this.pdfData.subTables.map(v => {
        v.pos = [v.row != null ? v.row : -1, v.col != null ? v.col : -1]
        return v
      });
    },
  },
  methods:{
    chartTypeName(imageTypeId) {
      if (imageTypeId == 'histogram' || imageTypeId == 'twoHistogram') {
        return "柱形图"
      } else if (imageTypeId == 'brokenLine') {
        return "折线图"
      }else if (imageTypeId == 'annulus') {
        return "环形图"
      }


    },
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  padding 20px 0

  .title
    font-size: 16px;
    font-family: Helvetica;
    color: #061178;
    line-height: 30px;

  .desc
    margin-top 5px
    font-size 14px

  .chart-wrapper
    margin-top 0px

  .pie
    width 100%;
    margin 0 auto;
</style>
