<template>
  <div>
    <div ref="container"></div>
  </div>
</template>

<script>
import {Graph} from '@antv/x6';
import {percentage, round} from "../../../../utils/format";
import {getAutoMiddlePageToConfig} from "../../../../utils/helper";

export default {
  name: "UpstreamAndDownstreamRiskMobile",
  props: {
    upStreams: Array,
    downStreams: Array,
    upstreamAndDownstreamCurrentNode: Object
  },
  data() {
    return {
      nodeStreamWidth: 160,
      ports :{
        groups: {
          // 输入链接桩群组定义
          left: {
            position: 'left',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
          // 输出链接桩群组定义
          right: {
            position: 'right',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
          // 输入链接桩群组定义
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
          // 输出链接桩群组定义
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
        },
        items: [
          {
            id: 'left',
            group: 'left',
          },
          {
            id: 'right',
            group: 'right',
          },
          {
            id: 'top',
            group: 'top',
          },
          {
            id: 'bottom',
            group: 'bottom',
          }
        ],
      },
      data: {
        // 节点
        nodes: [],
        // 边
        edges: [
          // {
          //   source: 'node1', // String，必须，起始节点 id
          //   target: 'node2', // String，必须，目标节点 id
          // },
        ],

      }
    }
  },
  mounted() {
    this.init()
    const graph = new Graph({
      container: this.$refs['container'],
      height: this.height,
      interacting: false
    });

    graph.fromJSON(this.data)
    console.log(this.data)


  },
  methods: {
    init() {
      const width = this.$refs['container'].getBoundingClientRect().width;

      const height = this.$refs['container'].getBoundingClientRect().width;
      const y= height / this.upStreams.length; //- this.nodeStreamWidth / 2

      for (const index in this.upStreams) {
        const node = this.upStreams[index]
        const color = node.risk?'#ec5757':'#6293f8'
        const colorBg = node.risk?'#fff':'#fff'
        const colorBorder = node.risk?'#ec5757':'#6293f8'

        this.data.nodes.push({
          id: 'upNode' + index, // String，可选，节点的唯一标识
          x: 0,       // Number，必选，节点位置的 x 值
          y: (y+100) * index,       // Number，必选，节点位置的 y 值
          width: 80,   // Number，可选，节点大小的 width 值
          height: 100,  // Number，可选，节点大小的 height 值
          label: node.name+'\n'+node.totalAmout+"万元", // String，节点标签
          ports: this.ports,
          proportion:node.proportion,
          risk:node.risk,
          attrs: {
            body: {
              fill: colorBg, // 背景颜色
              stroke: colorBorder
            },
            label: {
              textWrap: {width: '100%'},
              lineHeight: 20,
              fill: color,
              fontSize: 10,    // 文字大小
            }
          }
        },)
      }

      const x2 = width / this.downStreams.length;
      const y2= height / this.upStreams.length;
      for (const index in this.downStreams) {
        const node = this.downStreams[index]
        const color = node.risk?'#ec5757':'#6293f8'
        const colorBg = node.risk?'#fff':'#fff'
        const colorBorder = node.risk?'#ec5757':'#6293f8'
        this.data.nodes.push({
          id: 'downNode' + index, // String，可选，节点的唯一标识
          x: width-80,       // Number，必选，节点位置的 x 值
          y: (y2+100) * index,       // Number，必选，节点位置的 y 值

          width: 80,   // Number，可选，节点大小的 width 值
          height: 100,  // Number，可选，节点大小的 height 值
          label: node.name+'\n'+node.totalAmout+"万元", // String，节点标签
          ports: this.ports,
          proportion:node.proportion,
          risk:node.risk,
          attrs: {
            body: {
              fill: colorBg, // 背景颜色
              stroke: colorBorder
            },
            label: {
              textWrap: {width: '100%'},
              lineHeight: 20,
              fill: color,
              fontSize: 10,    // 文字大小
            }
          }
        },)
      }


      this.data.nodes.push({
        id: 'theCurrentNode', // String，可选，节点的唯一标识
        x: width / 2-25/2,       // Number，必选，节点位置的 x 值
        y: 180,       // Number，必选，节点位置的 y 值
        width: 25,   // Number，可选，节点大小的 width 值
        height: this.upstreamAndDownstreamCurrentNode.name.length*23,  // Number，可选，节点大小的 height 值
        label: this.upstreamAndDownstreamCurrentNode.name, // String，节点标签
        ports:this.ports,


        attrs: {
          body: {
            fill: '#6293f8', // 背景颜色
            stroke: '#6293f8',
          },
          label: {
            textWrap: {width: '100%'},
            lineHeight: 20,
            fill: '#fff',
            fontSize: 14,    // 文字大小
          }
        }
      },)

      for (const node of this.data.nodes) {
        if(node.id.startsWith("upNode")){
          this.data.edges.push({
            source: { cell: 'theCurrentNode', port: 'left' },
            target: {
              cell:  node.id,
              port: 'right', // 链接桩 ID
            },
            router: 'manhattan',
            vertices:[
              { x: node.x+135, y: node.y+50 },
            ],
            labels: [
              {
                attrs: { label: { text: percentage(node.proportion),stroke: "#6293f8",fontSize: 12 },rect: {
                    ref: 'label',
                    fill: '#fff',
                    rx: 3,
                    ry: 3,
                    refWidth: 1,
                    refHeight: 1,
                    refX: 0,
                    refY: 0,
                  } },
                position :{
                  distance:1,
                  offset:{ x: 30, y: 0 }
                }
              },
            ],
            attrs: {
              line: {
                stroke: "#6293f8", // 指定 path 元素的填充色
                sourceMarker: 'block',
                targetMarker: {
                  rx: 0, // 椭圆箭头的 x 半径
                  ry: 0,  // 椭圆箭头的 y 半径
                },
              },
            },
          })
        }else if(node.id.startsWith("downNode")){
          this.data.edges.push({
            source: { cell: 'theCurrentNode', port: 'right' },
            target: {
              cell:  node.id,
              port: 'left', // 链接桩 ID
            },
            labels: [
              {
                attrs: { label: { text: percentage(node.proportion),stroke: "#6293f8",
                    fontSize: 12, } ,rect: {
                    ref: 'label',
                    fill: '#fff',
                    rx: 3,
                    ry: 3,
                    refWidth: 1,
                    refHeight: 1,
                    refX: 0,
                    refY: 0,
                  },},
                position :{
                  distance:1,
                  offset:{ x: -30, y: 0 }
                }
              },
            ],

            router: 'manhattan',

            vertices:[
              { x: node.x-56, y: node.y+50 },
            ],
            attrs: {
              line: {
                stroke: "#6293f8", // 指定 path 元素的填充色
              },
            },
          })
        }


      }

      //this.upStreams.length
    }
  },
  computed:{
    height(){
      return 135*Math.max(this.downStreams.length,this.upStreams.length)
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
