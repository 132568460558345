let mixin = {
  computed: {
    //给第三方的特殊模式页面： 不跳转到详情页面
    isSpecialMode(){
      //return this.$route.name == 'result-head-always'
      return false
    },
  }
};

export default mixin;
