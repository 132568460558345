<template>
  <div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import echarts from "echarts";
export default {
  mounted() {
    this.showMap();
  },
  props: {
    colors: Array,
    data:Array
  },
  methods: {
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = "300px"; //window.innerWidth * 0.5 + "px";
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);

      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {d}%"
        },
        legend: {
          show: false
        },
        series: [
          {
            name: "金额",
            type: "pie",
            radius: ["40%", "80%"],
            avoidLabelOverlap: false,
            color: this.colors,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "12",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: this.data
          }
        ]
      };

      this.chartColumn.setOption(option);
    }
  }
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 200px;
  height: 200px;
}
</style>
