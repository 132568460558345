<template>
  <div>
    <GenericTableMobile :data="table">

    </GenericTableMobile>

  </div>
</template>

<script>

import GenericTableMobile
  from "../../../ResultEditor/components/InvoiceIndex/components/GenericTableMobile";

export default {
  components: {
    GenericTableMobile
  },

  props: {
    data: Object
  },
  name: "FinanceRatio",
  mounted() {


  },
  computed: {
    ths() {
      return this.data.jsonData.yData.map(v => {
        return v.name
      })
    },
    trs() {
      const ret = [];
      this.data.jsonData.yData.forEach((v, colIndex) => {

        v.yData.forEach((data, rowIndex) => {
          if(!ret[rowIndex]){
            ret[rowIndex] = []
          }
          ret[rowIndex][colIndex]=data
        })
      })
      return ret;
    },
    table(){
      return {
        labels:this.ths,
        data:this.trs
      }
    }


  }
}
</script>

<style lang="stylus" scoped>

.table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 22px;
  width 100%

  td, th {
    text-align: left;
    font-size: 14px;
    padding: 8px 16px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}

</style>

