<template>
  <div ref="theChart" class="echarts1" style="height: 500px"></div>
</template>
<script>
import echarts from "echarts";
import smoothNumber from "@/utils/smooth";


export default {
  props: {
    chartData: Object
  },
  data() {
    return {
      colors: [
        "#E86452",
        "#F6BD16",
        "#5AD8A6",
        "#6293F8",
        "#1D73C4",
        "#541DC4",
        "#599EF7",
        "#59F78C",
        "#F7A659",
        "#F75959",
        "#000000",
      ],
    };
  },
  computed: {
    source() {
      const ret = [['product', ...this.chartData.xData]]
      this.chartData.yData.forEach(v => {
        ret.push([v.name, ...v.yData])
      })
      return ret;
    }
  },
  mounted() {


    const myChart = echarts.init(this.$refs.theChart);

    const options = {
      legend: {
        x: "left"
      },
      tooltip: {
        trigger: "item",
        formatter(params) {
          return `${params.dimensionNames[params.seriesIndex + 1]}:${params.data[params.seriesIndex + 1]}万元`
        }
      },
      dataset: {
        source: this.source
      },

      yAxis: [
        {
          type: 'category', gridIndex: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "solid",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },
        },

      ],
      xAxis: {
        type: "value",
        splitNumber: 2,
        axisLabel: {formatter: '{value} 万元'},
        axisLine: {
          show: true,
          lineStyle: {
            color: "#666",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EEEEEE",
            width: 1,
            type: "dashed",
          },
        },
      },
      grid: {
        left: "3%",
        right: "7%",
        top: "10%",
        bottom: "5%",
        containLabel: true,
      },
      series: [
        // These series are in the first grid.
        {
          type: 'bar',
          seriesLayoutBy: 'row',
          barCategoryGap: '0%',
          barGap: '0%',
          color: this.colors[3],
          barWidth: "30%",
          label: {
            normal: {
              show: true,
              position: 'right',
              textStyle: {
                size:'12px',
                color: '#999'
              }
            }
          },
        },
        {
          type: 'bar',
          seriesLayoutBy: 'row',
          barCategoryGap: '0%',
          barGap: '0%',
          color: this.colors[2],
          barWidth: "30%",
          label: {
            normal: {
              show: true,
              position: 'right',
              textStyle: {
                size:'12px',
                color: '#999'
              }
            }
          },
        },

      ]
    };
    myChart.setOption(options);
  },
};
</script>
<style lang="stylus" scoped>
.echarts1 {
}

.inOutCompare {

}
</style>
