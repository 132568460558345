<template>
  <div>
    <GenericTableMobile :data="tableData">

    </GenericTableMobile>
  </div>
</template>

<script>
import city from 'echarts/map/json/china.json'
import echarts from 'echarts'
import GenericTableMobile
  from "../../../ResultEditor/components/InvoiceIndex/components/GenericTableMobile";


export default {
  components:{
    GenericTableMobile
  },
  props: {
    geoCoordMap: Object,
    valueData: Object
  },
  data() {
    return {};
  },
  mounted() {

  },
  computed:{
    tableData(){
      const rawData = Object.entries(this.valueData).sort((a,b)=>{return b[1]-a[1]});
      const labels = ['排名','城市','不含税金额'];
      const data = rawData.map((v,index)=>{
        return [index+1,v[0],v[1]+'万元']
      })
      return {labels,data};
    },


  },

  methods: {


  }

};
</script>

<style lang="stylus" scoped>
.echart
  height 600px
  width 749px
</style>
