<template>
  <div>
    <div class="wrapper">
      <div class="col">
        <div class="row">
          <div class="summary-evaluate">
            <div class="name">综合评分</div>
            <div class="rate">
              <el-rate
                :value="data.score/20"
                :max="max"
                disabled
                text-color="#FF8023"
                :colors="['#FF8023','#FF8023','#FF8023']"
              >
              </el-rate>
              <div class="rate-score">{{ data.score }}分</div>
            </div>
          </div>

          <RadarChart :data="summaryRunningAbilityTableData"
                      @click="handleRadarChartClick"></RadarChart>
        </div>


      </div>
      <div class="col table">
        <div v-if="abilityDetailTableData">
          <div class="btns">
            <button class="btn btn-default btn-back" @click="setAbilityDetail(null)">返回</button>
          </div>
          <GenericTable width="100%" :data="abilityDetailTableData">

          </GenericTable>
        </div>
        <div v-else>
          <div class="table2">
            <table class="rank-table">
              <thead>
              <tr>
                <th style="width: 200px">项目</th>
                <th>评分</th>
              </tr>
              </thead>
              <tr v-for="(row,key) in summaryRunningAbilityTableData"
                  :key="key">
                <td>
                  <div class="clickable"
                       @click="setAbilityDetail(key)">
                    {{ row.name }}
                  </div>
                </td>
                <td>
                  <div class="rate">
                    <el-rate
                      :value="row.value/20"
                      :max="max"
                      disabled
                      text-color="#FF8023"
                      :colors="['#FF8023','#FF8023','#FF8023']"
                    >
                    </el-rate>
                                       <span style="margin-left: 5px">{{row.value}}分</span>
                  </div>

                </td>
              </tr>
            </table>

            <!--            <el-table-->
            <!--              :data="summaryRunningAbilityTableData"-->
            <!--              style="width: 400px">-->
            <!--              <el-table-column-->
            <!--                prop="name"-->
            <!--                label="项目"-->
            <!--              >-->
            <!--                <template slot-scope="scope">-->
            <!--                  <div class="clickable" v-if="scope"-->
            <!--                       @click="setAbilityDetail(scope.$index)">-->
            <!--                    {{ scope.row.name }}-->
            <!--                  </div>-->
            <!--                </template>-->
            <!--              </el-table-column>-->
            <!--              <el-table-column-->
            <!--                prop="scale"-->
            <!--                label="评分">-->
            <!--                <template slot-scope="scope">-->
            <!--                  <div class="rate">-->
            <!--                    <el-rate-->
            <!--                      :value="scope.row.value/20"-->
            <!--                      :max="max"-->
            <!--                      disabled-->
            <!--                      text-color="#FF8023"-->
            <!--                      :colors="['#FF8023','#FF8023','#FF8023']"-->
            <!--                    >-->
            <!--                    </el-rate>-->
            <!--                    {{scope.row.value}}分-->
            <!--                  </div>-->

            <!--                </template>-->
            <!--              </el-table-column>-->
            <!--            </el-table>-->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import RadarChart from "@/pages/ResultEditor/components/ResultHead/RadarChart";
import GenericTable from "../InvoiceIndex/components/GenericTable";

export default {
  name: "RunningAbility",
  props: {
    data: Object
  },
  components: {
    RadarChart,
    GenericTable
  },
  computed: {
    summaryRunningAbilityTableData() {
      return this.data.data.map((item) => {
        return {name: item.name, value: item.score}
      })
    },
    abilityDetailTableData() {
      if (this.currentAbilityIndex == null) {
        return null;
      }
      const item = this.data.data[this.currentAbilityIndex].data;
      const data = item.body.map(v => {
        return [
          v[''],
          v['本期'],
          v['上期'],
          v['同期变动']
        ]
      })
      return {
        data,
        labels: item.header
      }
    },
  },
  data() {
    return {
      currentAbilityIndex: null,
      max: 5,
      value: 3.7
    }
  },
  methods: {
    setAbilityDetail(index) {
      this.currentAbilityIndex = index;
    },
    handleRadarChartClick() {

    }
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  display flex;

  >>> .el-rate__icon
    font-size 22px

  .summary-evaluate
    display flex

    .name
      font-size: 16px;
      color: #666
      margin-right 10px

    .rate
      display flex
      align-items center

      .rate-score
        color rgb(255, 128, 35);
        font-size 14px

  .col
    width 50%

  .table
    margin-top 0px
    height 452px
    border: 1px solid #d9d9d9;
    position relative

    .btns
      position absolute
      display flex;
      justify-content flex-end
      right 0
      top: -40px


>>> .el-table
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 0px;
  min-height 100%

  td, th {
    text-align: left;
    font-size: 14px;
    padding: 8px 16px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    min-width: 100px;

    .cell {
      color: rgba(0, 0, 0, 0.6);
      padding 0
    }


  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }

>>> .table
  margin-top 0

  th, td
    font-size 14px

.rate
  display flex

.rank-table {
  width 100%
  cellspacing 0
  border-collapse: collapse;

  tr:nth-child(even){
    background #f5f5f5
  }

  th {
    font-weight: 600;
    background: #f0f5ff;
    text-align center
    padding 8px 16px
    border: 1px solid #eee;
  }
  td{
    border: 1px solid #eee;
    text-align center
    padding 8px 16px
    .clickable {
      color: #2F54EB;
      cursor: pointer;
      text-decoration underline
    }
  }

}

</style>
