<template>
  <div>
    <div class="wrapper">
      <svg width="1046px" height="1132px" viewBox="0 0 1046 1132" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="4.13可视化报告新增能力分析模块" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="4.13可视化报告（新增能力分析模块）" transform="translate(-437.000000, -2241.000000)">
            <g id="客户分布" transform="translate(319.000000, 2148.000000)">
              <g id="编组-9" transform="translate(119.308380, 94.000000)">
                <g id="编组-6" transform="translate(160.451926, 52.000000)" stroke="#D9D9D9" stroke-linejoin="round">
                  <line x1="4" y1="470.975" x2="4" y2="462.975" id="路径" transform="translate(4.000000, 466.975000) rotate(90.000000) translate(-4.000000, -466.975000) "></line>
                  <line x1="12" y1="262.975" x2="12" y2="254.975" id="路径" transform="translate(12.000000, 258.975000) rotate(90.000000) translate(-12.000000, -258.975000) "></line>
                  <line x1="12" y1="678.410914" x2="12" y2="670.410914" id="路径" transform="translate(12.000000, 674.410914) rotate(90.000000) translate(-12.000000, -674.410914) "></line>
                  <line x1="9.06868644" y1="674.975" x2="9.06868644" y2="259.539086" id="路径" transform="translate(9.068686, 466.975000) rotate(180.000000) translate(-9.068686, -466.975000) "></line>
                  <line x1="179.931314" y1="267.725" x2="179.931314" y2="259.725" id="路径" transform="translate(179.931314, 263.725000) rotate(90.000000) translate(-179.931314, -263.725000) "></line>
                  <line x1="187.931314" y1="139.75" x2="187.931314" y2="131.75" id="路径" transform="translate(187.931314, 135.750000) rotate(90.000000) translate(-187.931314, -135.750000) "></line>
                  <line x1="187.931314" y1="395.7" x2="187.931314" y2="387.7" id="路径" transform="translate(187.931314, 391.700000) rotate(90.000000) translate(-187.931314, -391.700000) "></line>
                  <line x1="185" y1="391.75" x2="185" y2="135.8" id="路径" transform="translate(185.000000, 263.750000) rotate(180.000000) translate(-185.000000, -263.750000) "></line>
                  <line x1="355.931314" y1="132.5" x2="355.931314" y2="124.5" id="路径" transform="translate(355.931314, 128.500000) rotate(90.000000) translate(-355.931314, -128.500000) "></line>
                  <line x1="363.931314" y1="4.5" x2="363.931314" y2="-3.5" id="路径" transform="translate(363.931314, 0.500000) rotate(90.000000) translate(-363.931314, -0.500000) "></line>
                  <line x1="363.931314" y1="262.435914" x2="363.931314" y2="254.435914" id="路径" transform="translate(363.931314, 258.435914) rotate(90.000000) translate(-363.931314, -258.435914) "></line>
                  <line x1="361" y1="258.5" x2="361" y2="0.564086262" id="路径" transform="translate(361.000000, 129.500000) rotate(180.000000) translate(-361.000000, -129.500000) "></line>
                  <line x1="355.931314" y1="581.825" x2="355.931314" y2="573.825" id="路径" transform="translate(355.931314, 577.825000) rotate(90.000000) translate(-355.931314, -577.825000) "></line>
                  <line x1="363.931314" y1="518.357043" x2="363.931314" y2="510.357043" id="路径" transform="translate(363.931314, 514.357043) rotate(90.000000) translate(-363.931314, -514.357043) "></line>
                  <line x1="363.931314" y1="645.292957" x2="363.931314" y2="637.292957" id="路径" transform="translate(363.931314, 641.292957) rotate(90.000000) translate(-363.931314, -641.292957) "></line>
                  <line x1="361" y1="641.357043" x2="361" y2="514.421129" id="路径" transform="translate(361.000000, 577.857043) rotate(180.000000) translate(-361.000000, -577.857043) "></line>
                  <line x1="355.931314" y1="966.575" x2="355.931314" y2="958.575" id="路径" transform="translate(355.931314, 962.575000) rotate(90.000000) translate(-355.931314, -962.575000) "></line>
                  <line x1="363.931314" y1="903.107043" x2="363.931314" y2="895.107043" id="路径" transform="translate(363.931314, 899.107043) rotate(90.000000) translate(-363.931314, -899.107043) "></line>
                  <line x1="363.931314" y1="1030.04296" x2="363.931314" y2="1022.04296" id="路径" transform="translate(363.931314, 1026.042957) rotate(90.000000) translate(-363.931314, -1026.042957) "></line>
                  <line x1="361" y1="1026.10704" x2="361" y2="899.171129" id="路径" transform="translate(361.000000, 962.607043) rotate(180.000000) translate(-361.000000, -962.607043) "></line>
                  <line x1="180.451926" y1="678.075" x2="180.451926" y2="670.075" id="路径" transform="translate(180.451926, 674.075000) rotate(90.000000) translate(-180.451926, -674.075000) "></line>
                  <line x1="188.451926" y1="582.075" x2="188.451926" y2="574.075" id="路径" transform="translate(188.451926, 578.075000) rotate(90.000000) translate(-188.451926, -578.075000) "></line>
                  <line x1="188.451926" y1="774.075" x2="188.451926" y2="766.075" id="路径" transform="translate(188.451926, 770.075000) rotate(90.000000) translate(-188.451926, -770.075000) "></line>
                  <line x1="185.520612" y1="770.075" x2="185.520612" y2="578.075" id="路径" transform="translate(185.520612, 674.075000) rotate(180.000000) translate(-185.520612, -674.075000) "></line>
                </g>
                <g id="1" transform="translate(0.000000, 466.975000)" fill-rule="nonzero">
                  <g id="编组-18" transform="translate(0.000000, -0.000000)">
                    <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="-2.13162821e-14" width="160" height="104"></rect>
                    <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111" height="32"></rect>
                  </g>
                  <text id="净资产收益率" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="38" y="26">净资产收益率</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['净资产收益率上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['净资产收益率']}}</tspan>
                  </text>
                </g>
                <g id="2" transform="translate(176.451926, 258.475000)" fill-rule="nonzero">
                  <g id="编组-19">
                    <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="160" height="104"></rect>
                    <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                    <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  </g>
                  <text id="总资产净利率" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="38" y="26">总资产净利率</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['总资产净利率上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['总资产净利率']}}</tspan>
                  </text>
                </g>
                <g id="3" transform="translate(176.451926, 674.075000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="161" height="104"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="权益乘数（杠杆率）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="17" y="26">权益乘数（杠杆率）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['权益乘数（杠杆率）上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['权益乘数（杠杆率）']}}</tspan>
                  </text>
                </g>
                <g id="4" transform="translate(352.383240, 135.250000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="销售净利率" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="45" y="26">销售净利率</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['销售净利率上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['销售净利率']}}</tspan>
                  </text>
                </g>
                <g id="5" transform="translate(352.383240, 385.200000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="总资产周转率" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="38" y="26">总资产周转率</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['总资产周转率上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['总资产周转率']}}</tspan>
                  </text>
                </g>
                <g id="6" transform="translate(352.903852, 577.575000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="资产总额（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="24" y="25">资产总额（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['资产总额上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['资产总额']}}</tspan>
                  </text>
                </g>
                <g id="7" transform="translate(352.903852, 769.950000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="所有者权益（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="17" y="25">所有者权益（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['所有者权益上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['所有者权益']}}</tspan>
                  </text>
                </g>
                <g id="11" transform="translate(528.383240, 513.450000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="流动资产（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="24" y="25">流动资产（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['流动资产上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['流动资产']}}</tspan>
                  </text>
                </g>
                <g id="12" transform="translate(528.383240, 641.700000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <text id="非流动资产（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="17" y="25">非流动资产（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['非流动资产上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['非流动资产']}}</tspan>
                  </text>
                </g>
                <g id="8" transform="translate(528.383240, 0.000000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="考虑税负因素" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="38" y="26">考虑税负因素</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['考虑税负因素上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['考虑税负因素']}}</tspan>
                  </text>
                </g>
                <g id="9" transform="translate(528.383240, 128.250000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="考虑利息负担" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="38" y="26">考虑利息负担</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['考虑利息负担上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['考虑利息负担']}}</tspan>
                  </text>
                </g>
                <g id="10" transform="translate(528.383240, 256.500000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="经营利润率" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="45" y="26">经营利润率</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['经营利润率上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['经营利润率']}}</tspan>
                  </text>
                </g>
                <g id="13" transform="translate(528.383240, 769.950000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="资产负债率" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="45" y="25">资产负债率</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['资产负债率上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5.33%" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['资产负债率']}}</tspan>
                  </text>
                </g>
                <g id="21" transform="translate(352.903852, 962.325000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="负债总额（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="24" y="25">负债总额（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="61">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['负债总额上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['负债总额']}}</tspan>
                  </text>
                </g>
                <g id="14" transform="translate(704.383240, 0.000000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="净利润（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="37" y="26">净利润（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['净利润上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['净利润']}}</tspan>
                  </text>
                </g>
                <g id="15" transform="translate(704.383240, 128.250000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="利润总额（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="24" y="26">利润总额（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['利润总额上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['利润总额']}}</tspan>
                  </text>
                </g>
                <g id="16" transform="translate(704.383240, 256.500000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="EBIT息税前利润（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="5.243" y="26">EBIT息税前利润（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['EBIT息税前利润上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['EBIT息税前利润']}}</tspan>
                  </text>
                </g>
                <g id="17" transform="translate(880.383240, 0.000000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="利润总额（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="24" y="26">利润总额（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['利润总额上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['利润总额']}}</tspan>
                  </text>
                </g>
                <g id="18" transform="translate(880.383240, 128.250000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="EBIT息税前利润（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="6.243" y="26">EBIT息税前利润（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['EBIT息税前利润上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['EBIT息税前利润']}}</tspan>
                  </text>
                </g>
                <g id="19" transform="translate(880.383240, 256.500000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="营业总收入（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="17" y="26">营业总收入（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="87,500,438.6" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['营业总收入上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="87,500,438.6" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['营业总收入']}}</tspan>
                  </text>
                </g>
                <g id="19-2" transform="translate(527.691620, 385.199951)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="营业总收入（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="17" y="26">营业总收入（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="87,500,438.6" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['营业总收入上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="87,500,438.6" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['营业总收入']}}</tspan>
                  </text>
                </g>
                <g id="20" transform="translate(704.383240, 385.200000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="160" height="104"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111" height="32"></rect>
                  <text id="平均资产总额（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="10" y="25">平均资产总额（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="87,500,438.6" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['平均资产总额上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="87,500,438.6" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['平均资产总额']}}</tspan>
                  </text>
                </g>
                <g id="22" transform="translate(528.383240, 898.200000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="流动负债（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="24" y="25">流动负债（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['流动负债上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['流动负债']}}</tspan>
                  </text>
                </g>
                <g id="23" transform="translate(528.383240, 1026.450000)" fill-rule="nonzero">
                  <rect id="矩形" stroke="#FFFFFF" stroke-width="1.33015873" fill="#FFFFFF" x="0" y="0" width="153.529412" height="103.632353"></rect>
                  <rect id="矩形" fill="#207DFB" x="0" y="0" width="160" height="40"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="0" y="40" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="49" y="40" width="111" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="1.8189894e-12" y="72" width="48" height="32"></rect>
                  <rect id="矩形" fill="#EFF4FF" x="48.9855292" y="72" width="111.014471" height="32"></rect>
                  <text id="长期负债（万元）" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" fill="#FFFFFF">
                    <tspan x="24" y="25">长期负债（万元）</tspan>
                  </text>
                  <text id="上年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="10" y="62">上年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="62">{{data['长期负债上期']}}</tspan>
                  </text>
                  <text id="本年" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="9.99435285" y="94">本年</tspan>
                  </text>
                  <text id="5,847,538.87" font-family="SourceHanSansSC-Regular, Source Han Sans SC" font-size="14" font-weight="normal" fill="#000000" fill-opacity="0.8">
                    <tspan x="57" y="94">{{data['长期负债']}}</tspan>
                  </text>
                </g>
                <text id="×" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="248" y="517.975">×</tspan>
                </text>
                <text id="×" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="423.5" y="291.975">×</tspan>
                </text>
                <text id="×" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="600.25" y="115.525">×</tspan>
                </text>
                <text id="=" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="691" y="23.525"></tspan>
                  <tspan x="691" y="55.525">=</tspan>
                </text>
                <text id="=" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="691" y="148.525"></tspan>
                  <tspan x="691" y="180.525">=</tspan>
                </text>
                <text id="=" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="691" y="278.025"></tspan>
                  <tspan x="691" y="310.025">=</tspan>
                </text>
                <text id="=" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="515.167583" y="439.2">=</tspan>
                </text>
                <text id="÷" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="865" y="23.525"></tspan>
                  <tspan x="865" y="55.525">÷</tspan>
                </text>
                <text id="÷" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="865" y="182.525">÷</tspan>
                </text>
                <text id="÷" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="865" y="316.025">÷</tspan>
                </text>
                <text id="÷" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="687" y="439.2">÷</tspan>
                </text>
                <text id="+" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="603.75" y="629.275">+</tspan>
                </text>
                <text id="÷" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="424.5" y="724.275">÷</tspan>
                </text>
                <text id="+" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="604.25" y="1015.275">+</tspan>
                </text>
                <text id="资产总额-" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="265.5" y="981.275">资产总额-</tspan>
                </text>
                <text id="×" font-family="SourceHanSansSC-Bold, Source Han Sans SC" font-size="14" font-weight="bold" line-spacing="32" fill="#000000" fill-opacity="0.7">
                  <tspan x="600.25" y="245.025">×</tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "RunningAnalysis",
  props:{
    data:Object
  }
}
</script>

<style lang="stylus" scoped>
  .wrapper
    display flex
    justify-content center
</style>
