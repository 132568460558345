<template>
  <div class="bar-wrapper">
    <div class="bar">
      <el-tooltip placement="top">
        <div slot="content">{{name}}<br/>{{middleLabel}}</div>
        <div class="middle" :style="{opacity: middleVisible? 1:0}"></div>
      </el-tooltip>

      <div class="circle-wrapper yellow" :style="{left:50+posPercent(sq)+'%',opacity: sqVisible? 1:0}" >
        <div class="text">{{ sq/100|percentage }}</div>
        <div class="circle"></div>
      </div>

      <div class="circle-wrapper blue" :style="{left:50+posPercent(bq)+'%',opacity: bqVisible? 1:0}" >
        <div class="text">{{ bq/100|percentage }}</div>
        <div class="circle"></div>
      </div>

    </div>
  </div>
</template>

<script>
import {round} from "../../../../utils/format";

export default {
  name: "ProgressBar",
  props: {
    bq: Number,
    sq: Number,
    middle: Number,
    middleLabel: String,
    sqVisible: Boolean,
    bqVisible: Boolean,
    name:String,
    middleVisible: Boolean
  },
  computed: {
    half() {
      return Math.max(Math.abs(this.middle - this.sq), Math.abs(this.middle - this.bq)) * 2
    },
    begin() {
      return this.middle - this.half
    },
    end() {
      return this.middle + this.half
    },

  },
  methods: {
    posPercent(num) {
      //return this.half/(this.bq - this.middle) = 50/x
      return 50 * (num - this.middle) / this.half
    },
    round(num) {
      return round(num, 4)
    }
  }
}
</script>

<style lang="stylus" scoped>
.bar-wrapper
  width 100%

  .bar
    height 4px;
    width 100%;
    background #efefef
    position relative;

    .middle
      transition 0.2s all;
      width: 8px;
      height: 19px;
      background: #63DAAB;
      left 50%;
      position absolute;
      top - 18px;
      transform translateX(-50%);

    .circle-wrapper
      transition all 0.1s ease-in-out
      position absolute;
      display flex
      flex-direction column
      align-items center;
      justify-content center;
      left 0;
      top 0%;
      transform translate(-50%, -75%);
      font-size 12px

      .circle
        transition all 0.1s ease-in-out
        width: 22px;
        height: 22px;
        background: #409EFF;
        border-radius 50px
        position relative;
        z-index 99

      .circle:hover
        transform scale(1.5)

    .circle-wrapper.yellow
      color #F7C122;

      .text
        position relative;
        top 45px

      .circle
        background: #F7C122;


    .circle-wrapper.blue
      color #409EFF;

      .circle
        background: #409EFF;
</style>
