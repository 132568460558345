<template>
  <div>
    <div ref="chartColumn" class="chartColumn" :style="{width: width,height:height}"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import echarts from "echarts";

export default {
  mounted() {
    this.showMap();
  },
  props: {
    colors: Array,
    interact: {
      default:true,
      type:Boolean
    },
    data: Array,
    width: {
      default: '340px',
      type: String
    },
    height: {
      default: '250px',
      type: String
    }
  },
  methods: {
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = this.width; //window.innerWidth * 0.5 + "px";
      this.$refs["chartColumn"].style.height = this.height;
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);
      let option = [];
      if (this.data.length > 0) {
        option = {
          legend: {
            show: false,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c}'
          },
          series: [
            {
              name: "风险命中数量",
              type: "pie",
              radius: ["30%", "60%"],
              avoidLabelOverlap: false,
              color: this.colors,
              label: {
                show: true,
                normal: {
                  show: true,
                  formatter: "{c}",
                },
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
              data: this.data,
            },
          ],
        };
      } else {
        option = {
          legend: {
            show: false,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {d}%'
          },
          series: [
            {
              name: "风险命中数量",
              type: "pie",
              radius: ["30%", "60%"],
              avoidLabelOverlap: false,
              color: ["#aaa"],
              label: {
                show: true,
                normal: {
                  show: true,
                  formatter: "{d}%",
                },
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
              data: [{
                name: "无数据",
                value: 0
              }],
            },
          ],
        };
      }

      this.chartColumn.setOption(option);

      // 饼图点击跳转到指定页面
      this.chartColumn.on('click',  (param) =>{
          if(!this.interact){
            return
          }
          const id = "#fengXianCiYun"
          this.$scrollTo(id, 1000);
          this.$eventBus.$emit("ringChartClick",param)

      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 340px;
  height: 250px;
}
</style>
