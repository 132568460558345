<template>
  <div>
    <div class="wrapper">
      <div class="chart-wrapper">
        <SolidPieChat :colors="colors" :data="chatData"></SolidPieChat>
      </div>

      <div class="legends">
        <div class="legend" v-for="(item, index) in data" :key="index">
          <div class="title" :title="item.value.className">
            <div
              class="box"
              :style="{ background: colors[index % colors.length] }"
            ></div>
            {{ item.value.simpleName||item.value.className }}
          </div>
          <!-- <div class="value">{{ item.value }}个</div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SolidPieChat from "./SolidPieChatForResult";
export default {
  props: {
    data: Array,
  },
  components: {
    SolidPieChat,
  },
  data() {
    return {
      colors: [
        "#E86452",
        "#F6BD16",
        "#5AD8A6",
        "#6293F8",
        "#1D73C4",
        "#541DC4",
        "#599EF7",
        "#59F78C",
        "#F7A659",
        "#F75959",
        "#000000",
      ],
    };
  },
  computed:{
    chatData(){
      return this.data.map(v=>{
        return {name:v.value.className,value:v.value.clazzRiskSize,id:v.value.id}
      })
    }
  }
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  width: 360px;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .chart-wrapper {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .legends {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .legend {
      width: 25%;
      flex-shrink: 0;
      flex-grow: 0;
      color: #666;
      display: flex;
      vertical-align: top;
      margin: 6px 0;
      justify-content: center;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;

      .box {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius 3px
        background: red;
        margin-right: 5px;
      }

      .title {
        vertical-align: top;
        width 95%
      }

      .value {
        vertical-align: top;
      }
    }
  }
}
</style>
