<template>
  <div>
    <div class="wrapper">
      <div class="chart-wrapper">
        <SolidPieChatForResult :interact="false" :colors="colors" :data="chatData" width="300px" height="200px"></SolidPieChatForResult>
      </div>

      <div class="legends">
        <div class="legend" v-for="(item, index) in data" :key="index">
          <div class="title">
            <div
              class="box"
              :style="{ background: colors[index % colors.length] }"
            ></div>
            {{ item.value.simpleName||item.value.className }}
          </div>
          <!-- <div class="value">{{ item.value }}个</div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SolidPieChatForResult from "./SolidPieChatForResult";
export default {
  props: {
    data: Array,
  },
  components: {
    SolidPieChatForResult,
  },
  data() {
    return {
      colors: [
        "#F53640",
        "#BBA1F2",
        "#F7C122",
        "#95A5CD",
        "#63DAAB",
        "#BCA88D",
        "#6293F8",
        "#F7A659"
      ],
    };
  },
  computed:{
    chatData(){
      return this.data.map(v=>{
        return {name:v.value.className,value:v.value.clazzRiskSize,id:v.value.id}
      })
    }
  }
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  width 100%
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background none !important;

  .chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .legends {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    width 80%


    flex-wrap: wrap;
    align-items center;
    margin 0 auto
    line-height 1;

    .legend {
      min-width: 50%
      position relative;
      justify-content center;
      flex-shrink: 1;
      flex-grow: 0;
      color: #666;
      display: flex;
      vertical-align: top;
      margin: 6px 0;
      align-items center;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      align-self center

      .box {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: red;
        margin-right: 5px;

      }

      .title {
        justify-content center;
        display flex
        vertical-align: top;
        width 95%
        flex-grow 0
      }

      .value {
        vertical-align: top;
      }
    }
  }
}
</style>
