<template>
  <div>
    <template v-if="isMobileDevice">
      <GenericTableMobile
        v-if="generalTableData"
        :data="generalTableData"
        :tips="tips"
        :subTables="subTables"
      ></GenericTableMobile>
    </template>
    <template v-else>
      <GenericTable
        v-if="generalTableData"
        :data="generalTableData"
        :tips="tips"
        :subTables="subTables"
      ></GenericTable>
    </template>

  </div>
</template>

<script>
import GenericTable from "../../../ResultEditor/components/InvoiceIndex/components/GenericTable";
import GenericTableMobile
  from "../../../ResultEditor/components/InvoiceIndex/components/GenericTableMobile";

export default {
  name: "KeyFinanceTable",
  components:{
    GenericTableMobile,
    GenericTable
  },
  props:{
    data:Object
  },
  computed:{
    isMobileDevice() {
      return window.innerWidth < 600;
    },
    pdfData(){
      return this.jsonData;
    },
    jsonData(){
      return JSON.parse(this.data.jsonData)
    },
    //通用表格
    generalTableData() {

      if (!this.pdfData) {
        return null;
      }

      if (!this.pdfData.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < this.pdfData.body.length; i++) {
        ret[i] = [];

        this.pdfData.header.forEach(key => {

          ret[i].push(this.pdfData.body[i][key])
        })

      }

      return {
        data: ret,
        labels: this.pdfData.header
      };
    },
    tips() {
      return this.pdfData.tips && this.pdfData.tips.map(v => {
        return {
          pos: [v.row != null ? v.row : -1, v.col != null ? v.col : -1],
          text: v.text
        }
      })
    },
    subTables() {
      return this.pdfData.subTables && this.pdfData.subTables.map(v => {
        v.pos = [v.row != null ? v.row : -1, v.col != null ? v.col : -1]
        return v
      });
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>
