<template>
  <div class="qiu360-box white-box">
    <div class="qiu360-left">
      <img
        src="@/assets/result/qiu360gaowei.png"
        v-if="level == 3"
        class="qiu360-img"
      />
      <img
        src="@/assets/result/qiu360wei.png"
        v-if="level == 2"
        class="qiu360-img"
      />
      <img
        src="@/assets/result/qiu360liang.png"
        v-if="level == 1"
        class="qiu360-img"
      />
      <img
        src="@/assets/result/qiu360you.png"
        v-if="level == 0"
        class="qiu360-img"
      />
    </div>
    <div class="qiu360-right">
      <div class="legends">
        <div class="legend" v-for="(item, index) in data" :key="index">
          <div class="title">
            <div class="box" :style="{ background: item.color }"></div>
            {{ item.name }}
          </div>
          <!-- <div class="value">{{ item.value }}个</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    level: Number,
  },

  data() {
    return {
      data: [
        {
          name: "高危",
          color: "#F5222D",
        },
        {
          name: "危",
          color: "#F7C122",
        },
        {
          name: "良",
          color: "#63DAAB",
        },
        {
          name: "优",
          color: "#6293F8",
        },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.qiu360-box {
  padding: 10px 0px 0;
  display: flex;
  align-items: center;
  justify-content center;
  flex-direction column;

  .qiu360-left {
    .qiu360-img {
      width: 130px;
    }
  }

  .qiu360-right {
    flex-grow: 1;
    display flex

    .legends {
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      flex-direction row;
      flex-wrap: wrap;
      justify-content center;
      margin-top 15px

      .legend {

        flex-shrink: 0;
        flex-grow: 0;
        color: #666;
        display: flex;
        vertical-align: top;

        margin-right 20px
        justify-content: flex-start;
        margin: 5px;
        flex-grow 0
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;

        .box {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: red;
          margin-right: 5px;
          border-radius 2px
        }

        .title {
          vertical-align: top;
        }

        .value {
          vertical-align: top;
        }
      }

      .legend:last-child {
        margin-right 0px
      }
    }
  }
}
</style>
