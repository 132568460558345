<template>
  <div class="ciyun-wrapper">

    <div
      class="wordCloud__tagBall"
      :style="{width:`${this.width}px`,height:`${this.height}px`}"
      @mouseenter="handleSpeed(0.001)"
      @mouseleave="handleSpeed(400)"
    >
      <div
        @click="handleCiyunItemClick(item)"
        class="wordCloud__tag"
        v-for="(item, index) of filteredData"
        :key="index"
        :style="{color:colorize(item),...contentEle[index].style}"
      > <div class="text" :style="{opacity:item.show?1:0}">{{ item.titleName }}</div>
      </div>
    </div>
  </div>
</template>
<script>


import {getAutoRoutePushTo} from "../../../../utils/helper";
import {mapState} from "vuex";

export default {
  name: 'Ciyun',

  data() {
    return {
      color: ['#2D4DB6', '#04B67C', '#D1AF07', '#E27914', '#CB4A4D', '#B02690'],
      contentEle: [],
      width: 496,
      height: 496,
      direction: '-1',
      speed: 400,
      filteredData:[]
    }
  },
  props: {
    data: Array,
    legendsName: String,
  },
  created() {
    this.contentEle = this.data.map(() => ({
      x: 0,
      y: 0,
      z: 0,
      style: {}
    }));
  },
  mounted() {
    this.innit();

    //myChart.setOption(option);
  },
  computed: {
    ...mapState("ResultEditor", ["resultHeadLegends"]),

  },
  watch: {
    resultHeadLegends:{
      handler(value){
        if(!this.resultHeadLegends[this.legendsName]){
          return [];
        }


         this.filteredData = this.data.map(v => {
          return {
            ...v,
            show:this.resultHeadLegends[this.legendsName][v.riskLevel - 1]
          }
        })
      },
      deep:true,
      immediate:true
    }
  },
  methods: {
    handleCiyunItemClick(item) {
      this.$emit("click", item)
    },
    colorize(item) {
      var colors = ['#EA6C5B', '#F7C122', '#63DAAB'];
      return colors[(item.riskLevel - 1) % colors.length];
    },
    innit() {
      const RADIUSX = (this.width - 50) / 2;
      const RADIUSY = (this.height - 50) / 2;
      this.contentEle = [];
      for (let i = 0; i < this.data.length; i += 1) {
        const k = -1 + (2 * (i + 1) - 1) / this.data.length;
        const a = Math.acos(k);
        const b = a * Math.sqrt(this.data.length * Math.PI);
        const x = RADIUSX * Math.sin(a) * Math.cos(b);
        const y = RADIUSY * Math.sin(a) * Math.sin(b);
        const z = RADIUSX * Math.cos(a);
        const singleEle = {
          x,
          y,
          z,
          style: {}
        };
        this.contentEle.push(singleEle);
      }
      this.animate();
    },
    animate() {
      //this.rotateX();
      this.rotateY();
      this.move();

      window.requestAnimationFrame(this.animate);
    },
    rotateX() {
      const angleX = ['-1', '1'].includes(this.direction)
        ? Math.PI / Infinity
        : Math.PI / ((Number(this.direction) / 2) * Number(this.speed));
      const cos = Math.cos(angleX);
      const sin = Math.sin(angleX);
      this.contentEle = this.contentEle.map((t) => {
        const y1 = t.y * cos - t.z * sin;
        const z1 = t.z * cos + t.y * sin;
        return {
          ...t,
          y: y1,
          z: z1
        };
      });
    },
    rotateY() {
      const angleY = ['-2', '2'].includes(this.direction)
        ? Math.PI / Infinity
        : Math.PI / (Number(this.direction) * Number(this.speed));
      const cos = Math.cos(angleY);
      const sin = Math.sin(angleY);
      this.contentEle = this.contentEle.map((t,index) => {
        const x1 = t.x * cos - t.z * sin;
        const z1 = t.z * cos + t.x * sin;
        return {
          ...t,
          x: x1,
          z: z1
        };
      });
    },
    move() {
      const CX = this.width / 2;
      const CY = this.height / 2;
      this.contentEle = this.contentEle.map((singleEle) => {
        const {x, y, z} = singleEle;
        const fallLength = 500;
        const RADIUS = (this.width - 50) / 2;
        const scale = fallLength / (fallLength - z);
        const alpha = (z + RADIUS) / (2 * RADIUS);
        const left = `${x + CX - 15}px`;
        const top = `${y + CY - 15}px`;
        const transform = `translate(${left}, ${top}) scale(${scale})`;
        const style = {
          ...singleEle.style,
          opacity: alpha + 0.3,
          zIndex: parseInt(scale * 100, 10),
          transform
        };
        return {
          x,
          y,
          z,
          style
        };
      });
    },
    handleRotate(value) {
      this.direction = value;
    },
    handleSpeed(value) {
      this.speed = value;

    }
  }
};
</script>
<style lang="stylus" scoped>
.wordCloud__tagBall {
  margin: 50px 0;
  position: relative;
  margin-left 50%
  transform translateX(-80%)
}

.wordCloud__tag {
  transition all 0.1s ease-in-out
  cursor pointer;
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  color: green;
  text-decoration: none;
  font-size: 15px;
  font-family: '微软雅黑';
  font-weight: bold;
  .text{
    transition all 0.2s ease-in-out
  }
  & > hover {
    color: red;
  }
}

.wordCloud__tag .text:hover{
  transform scale(1.5)
}

.wordCloud__home {
  display: flex;
  justify-content: center;
}
</style>
