<template>
  <div ref="theChart" class="echart"></div>
</template>
<script>
import echarts from "echarts";
import smoothNumber from "@/utils/smooth";


export default {
  name:"RadarChart",
  props: {
    data: Array
  },
  data() {
    return {
      colors: [
        "#207DFB",
      ],
    };
  },
  computed: {
    indicator(){
      return this.data.map(v=>{
        return {
          text:v.name,
          value:v.value,
          max:100
        }
      })
    },
    values(){
      return this.data.map(v=>{
        return v.value
      })
    }
  },
  mounted() {


    const myChart = echarts.init(this.$refs.theChart);

    const options = {
      tooltip: {
        trigger: 'axis',
        //formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      legend: {
        show:false,
        left: 'center',
        data: [
          '财务分析',
        ]
      },
      radar: [
        {
          indicator: this.indicator,
          center: ['50%', '50%'],
          radius: 100,
        },
      ],
      series: [
        {
          type: 'radar',
          tooltip: {
            trigger: 'item'
          },
          areaStyle: {},
          data: [
            {
              value: this.values,
              name: '财务分析(分)',
              areaStyle: {
                color: '#207DFB',
                opacity:0.2
              },
              itemStyle: {
                normal: {
                  borderWidth: 2,
                  color: '#207DFB'
                }
              },
              lineStyle: {
                type: 'solid',
                color:'#207DFB',
                width:2
              },
              symbol: 'circle',
              symbolSize: 10,
            }
          ]
        },

      ]
    };


    myChart.setOption(options);

    myChart.on('click', (params) => {
      var city = params.name;
      if(city){
        this.$emit("click",city);
      }else{
        this.$emit("click",null);
      }
      //this.$router.push(getAutoMiddlePageToConfig(this.$route.params.uuid, this.type, this.$route, {area: city.replace("①", "").replace("②", "").replace("③", "")}));
    });


  },
};
</script>
<style lang="stylus" scoped>
.echart {
  width 100%
  height 300px
}

.inOutCompare {

}
</style>
