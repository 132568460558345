<template>
  <div>
    <div class="wrapper" >
      <div class="chart-wrapper">
        <SolidRingChatForResult :interact="false" :colors="colors" :data="data" width="210px" height="210px"></SolidRingChatForResult>
      </div>

      <div class="legends">
        <div class="legend" v-for="(item, index) in data" :key="index">
          <div class="title">
            <div
              class="box"
              :style="{ background: colors[index % colors.length] }"
            ></div>
            {{ item.name }}
          </div>
          <!-- <div class="value">{{ item.value }}个</div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SolidRingChatForResult from "./SolidRingChatForResult";

export default {
  props: {
    data: Array,
    direction: {
      default: 'column',
      type: String
    }
  },
  components: {
    SolidRingChatForResult,
  },
  data() {
    return {
      colors: [
        "#E86452",
        "#F6BD16",
        "#5AD8A6",
        "#6293F8",
        "#1D73C4",
        "#541DC4",
        "#599EF7",
        "#59F78C",
        "#F7A659",
        "#F75959",
        "#000000",
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {

  display flex;
  flex-direction column;
  width: 300px;
  justify-content: center;
  align-content: center;

  .chart-wrapper {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .legends {
    flex-shrink: 0;
    flex-grow: 1;
    display flex;
    flex-direction row
    flex-wrap wrap
    justify-content center;

    .legend {
      flex-shrink 0;
      flex-grow 0;
      color: #666;
      display: flex;
      vertical-align: top;
      margin: 0px 10px;
      justify-content center;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      display flex;
      flex-direction row

      .box {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: red;
        margin-right: 5px;
      }

      .title {
        vertical-align: top;
      }

      .value {
        vertical-align: top;
      }
    }
  }
}


</style>
