<template>
  <div>
    <div ref="container"></div>
    <div ref="tooltip" :style="{top:tooltipVisible?tooltipY:'-99999px',left:tooltipX}"  class="el-popover el-popper el-popover--plain is-dark tooltip" >
      <div class="el-popover__title">{{toolTipCurrentNode.name}}存在以下风险</div>
      <div class="risks">
        <div class="risk" v-for="(risk,key) in toolTipCurrentNode.riskList" :key="key">•{{risk}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Graph} from '@antv/x6';
import {percentage, round} from "../../../../utils/format";
import {getAutoMiddlePageToConfig} from "../../../../utils/helper";
import {throttle} from "lodash"

export default {
  name: "UpstreamAndDownstreamRisk",
  props: {
    upStreams: Array,
    downStreams: Array,
    upstreamAndDownstreamCurrentNode: Object
  },
  data() {
    return {
      toolTipCurrentNode:{},
      tooltipVisible:false,
      tooltipX:0,
      tooltipY:0,
      nodeStreamWidth: 160,
      ports :{
        groups: {
          // 输入链接桩群组定义
          left: {
            position: 'left',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
          // 输出链接桩群组定义
          right: {
            position: 'right',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
          // 输入链接桩群组定义
          top: {
            position: 'top',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
          // 输出链接桩群组定义
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                r: 0,
              },
            },
          },
        },
        items: [
          {
            id: 'left',
            group: 'left',
          },
          {
            id: 'right',
            group: 'right',
          },
          {
            id: 'top',
            group: 'top',
          },
          {
            id: 'bottom',
            group: 'bottom',
          }
        ],
      },
      data: {
        // 节点
        nodes: [],
        // 边
        edges: [
          // {
          //   source: 'node1', // String，必须，起始节点 id
          //   target: 'node2', // String，必须，目标节点 id
          // },
        ],
      }
    }
  },
  mounted() {
    this.init()
    const graph = new Graph({
      container: this.$refs['container'],
      height: 500,
      interacting: false
    });

    graph.fromJSON(this.data)

    graph.on('node:click', ({ e, x, y, node, view }) => {
      const theNode = this.data.nodes.find(v=>v.id==node.id)
      if(node.id.startsWith("up")){

        if(theNode.risk){ //有风险才能跳转
          this.$router.push(getAutoMiddlePageToConfig(this.$route.params.uuid,2, this.$route,{taxNo:theNode.taxNo} ));
        }

      }else{
        if(theNode.risk){ //有风险才能跳转
          this.$router.push(getAutoMiddlePageToConfig(this.$route.params.uuid,1, this.$route,{taxNo:theNode.taxNo} ));
        }
      }
    })


    graph.on('node:mouseover', throttle(({ e, x, y, node, view }) => {
      const theNode = this.data.nodes.find(v=>v.id==node.id)
      if(!theNode.riskList||theNode.riskList.length==0){
        this.tooltipVisible=false
        return
      }
      this.toolTipCurrentNode = theNode;
      const container = this.$refs['container']
      const tooltip = this.$refs['tooltip']
      const offsetTop = container.offsetTop
      const target = e.target;
      if(target.tagName.toLowerCase()==='rect'){
        const {x:x1,y:y1} = target.getBoundingClientRect();
        const {width:toolTipWidth,height:toolTipHeight} = tooltip.getBoundingClientRect();
        this.tooltipVisible = true
        this.tooltipX = (x1-toolTipWidth/2)+'px'
        this.tooltipY = y1+document.documentElement.scrollTop-toolTipHeight+'px'
      }
    },100))
    graph.on('node:mouseleave', ({ e, x, y, node, view }) => {
      this.tooltipVisible = false
    })
  },
  methods: {
    init() {
      const width = this.$refs['container'].getBoundingClientRect().width;
      const x = width / this.upStreams.length; //- this.nodeStreamWidth / 2

      for (const index in this.upStreams) {
        const node = this.upStreams[index]
        const color = node.risk?'#ec5757':'#6293f8'
        const colorBg = node.risk?'#fff':'#fff'
        const colorBorder = node.risk?'#ec5757':'#6293f8'

        this.data.nodes.push({
          ...node,
          id: 'upNode' + index, // String，可选，节点的唯一标识
          x: x * index,       // Number，必选，节点位置的 x 值
          y: 40,       // Number，必选，节点位置的 y 值
          width: 90,   // Number，可选，节点大小的 width 值
          height: 100,  // Number，可选，节点大小的 height 值
          label: node.name+'\n'+node.totalAmout+"万元", // String，节点标签
          name:node.name,
          ports: this.ports,
          proportion:node.proportion,
          taxNo:node.taxNo,
          risk:node.risk,
          attrs: {
            body: {
              fill: colorBg, // 背景颜色
              stroke: colorBorder
            },
            label: {
              textWrap: {width: '100%'},
              lineHeight: 20,
              fill: color,
              fontSize: 12,    // 文字大小
            }
          }
        },)
      }

      const x2 = width / this.downStreams.length;
      for (const index in this.downStreams) {
        const node = this.downStreams[index]
        const color = node.risk?'#ec5757':'#6293f8'
        const colorBg = node.risk?'#fff':'#fff'
        const colorBorder = node.risk?'#ec5757':'#6293f8'
        this.data.nodes.push({
          ...node,
          id: 'downNode' + index, // String，可选，节点的唯一标识
          x: x2 * index,       // Number，必选，节点位置的 x 值
          y: 350,       // Number，必选，节点位置的 y 值
          width: 90,   // Number，可选，节点大小的 width 值
          height: 100,  // Number，可选，节点大小的 height 值
          label: node.name+'\n'+node.totalAmout+"万元", // String，节点标签
          ports: this.ports,
          proportion:node.proportion,
          taxNo:node.taxNo,
          risk:node.risk,
          attrs: {
            body: {
              fill: colorBg, // 背景颜色
              stroke: colorBorder
            },
            label: {
              textWrap: {width: '100%'},
              lineHeight: 20,
              fill: color,
              fontSize: 12,    // 文字大小
            }
          }
        },)
      }


      this.data.nodes.push({
        id: 'theCurrentNode', // String，可选，节点的唯一标识
        x: width / 2 - 580 / 2,       // Number，必选，节点位置的 x 值
        y: 220,       // Number，必选，节点位置的 y 值
        width: 580,   // Number，可选，节点大小的 width 值
        height: 60,  // Number，可选，节点大小的 height 值
        label: this.upstreamAndDownstreamCurrentNode.name, // String，节点标签
        ports: this.ports,
        attrs: {
          body: {
            fill: '#6293f8', // 背景颜色
            stroke: '#6293f8',
          },
          label: {
            textWrap: {width: '100%'},
            lineHeight: 20,
            fill: '#fff',
            fontSize: 16,    // 文字大小
          }
        }
      },)

      for (const node of this.data.nodes) {
        if(node.id.startsWith("upNode")){
          this.data.edges.push({
            source: {cell: node.id, port: 'bottom'},
            target: {
              cell:  'theCurrentNode',
              port: 'top', // 链接桩 ID
            },
            router: 'manhattan',
            vertices:[
              { x: node.x+45, y: node.y+150 },
            ],
            // connector: 'rounded',
            labels: [
              {
                attrs: { label: { text: percentage(node.proportion),stroke: "#6293f8" } },
                position :{
                  distance:0,
                  offset:{ x: 0, y: 30 }
                }
              },
            ],
            attrs: {
              line: {
                stroke: "#6293f8", // 指定 path 元素的填充色
              },
            },
          })
        }else if(node.id.startsWith("downNode")){
          this.data.edges.push({
            source: {cell: 'theCurrentNode', port: 'bottom'},
            target: {
              cell:  node.id,
              port: 'top', // 链接桩 ID
            },
            router: 'manhattan',
            vertices:[
              { x: node.x+45, y: node.y-40 },
            ],
            labels: [
              {
                attrs: { label: { text: percentage(node.proportion),stroke: "#6293f8" } },
                position :{
                  distance:1,
                  offset:{ x: 0, y: -20 }
                }
              },
            ],
            attrs: {
              line: {
                stroke: "#6293f8", // 指定 path 元素的填充色
              },
            },
          })
        }


      }

      //this.upStreams.length
    }
  }
}
</script>

<style lang="stylus" scoped>
.tooltip{
  .el-popover__title{
    font-size: 14px;
    color #fff;
    margin-bottom 5px
  }
  font-size: 14px;
  color #fff;
  background rgba(0,0,0,0.7)
  padding 10px
  border-radius 10px
  .risks{
    display: flex;
    flex-wrap wrap
    max-width 480px;
    .risk{
      min-width: 120px;
      flex-shrink 0
      margin-top 8px
    }

  }

}
>>>.x6-node{
  cursor pointer
}
</style>
