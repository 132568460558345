<template>
  <div class="table-wrapper">
    <table class="table">
      <thead>
      <tr>
        <th v-for="(label, index) in labels" :key="index">{{ label }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in tableData" :key="index">
        <td v-for="(col, index2) in row" :key="index2">
          <el-tooltip class="item" effect="light" :content="tipText(index,index2)" placement="top-start"
                      :disabled="!tipText(index,index2)">
            <span v-if="col == 'button{detail}'"
                  class="clickable"
                  @click="handleCellClick(index)"
                  :class="{'text-has-tip':tipText(index,index2)}"
            ><a
              href="#"
              @click.prevent="handleToggleDetailCLick"
              class="link"
            >{{ toggleLabel }}</a
            ></span
            >
            <span v-else v-html="col"
                  :class="{'text-has-tip':tipText(index,index2),'text-has-table':!!subTable(index,index2)}"
                  @click="handleSubTableLinkClick(subTable(index,index2),index,index2)"></span>
          </el-tooltip>
        </td>
      </tr>
      </tbody>
    </table>

    <el-drawer
      :title="subTableInDrawer?subTableInDrawer.name:''"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose">
      <div class="drawer-inner">
        <GenericTable
          v-if="generalTableData"
          :data="generalTableData"
        ></GenericTable>
      </div>

    </el-drawer>
  </div>
</template>

<script>
import GenericTable from "@/pages/SimpleResultEditor/components/InvoiceIndex/components/GenericTable";

export default {
  components:{
    GenericTable
  },
  props: {
    data: Object,
    drawerTriggerIndex: Number,
    showDetails: Boolean,
    tips: Array,
    subTables: Array
  },
  computed: {

    tableData() {
      return this.data.data;
    },
    labels() {
      return this.data.labels;
    },
    toggleLabel() {
      return this.showDetails ? "收起" : "展开";
    },
    //通用表格
    generalTableData() {
      if (!this.subTableInDrawer) {
        return null;
      }
      const ret = [];
      for (let i = 0; i < this.subTableInDrawer.detailedTable.body.length; i++) {
        ret[i] = [];

        this.subTableInDrawer.detailedTable.header.forEach(key => {

          ret[i].push(this.subTableInDrawer.detailedTable.body[i][key])
        })

      }


      return {
        data: ret,
        labels: this.subTableInDrawer.detailedTable.header,
      };
    },
  },
  data() {
    return {
      drawer: false,
      subTableInDrawer: null
    };
  },
  methods: {
    handleSubTableLinkClick(tableData,index,index2) {
      if(!tableData){
        return
      }
      this.subTableInDrawer = tableData
      this.drawer= true
    },
    handleClose() {
      this.drawer = false
    },
    //点击打开表格
    subTable(row, col) {
      let ret = null;
      if (!this.subTables) {
        return null
      }
      const table = this.subTables.find(v => {
          let isShowTipInRow = false
          if (v.pos[0] == -1 || v.pos[0] == row) {
            isShowTipInRow = true
          }


          let isShowTipInCol = false
          if (v.pos[1] == -1 || v.pos[1] == col) {
            isShowTipInCol = true
          }
          if(isShowTipInRow && isShowTipInCol){

           // debugger
          }
          return isShowTipInRow && isShowTipInCol
        }
      )
      if (table) {
        ret = {
          detailedTable: table.detailedTable,
          name: table.name
        }
      }

      return ret
    },
    //需要提示的文案
    tipText(row, col) {
      let ret = null;
      if (!this.tips) {
        return null
      }
      const tip = this.tips.find(v => {
          let isShowTipInRow = false
          if (v.pos[0] == -1 || v.pos[0] == row) {
            isShowTipInRow = true
          }


          let isShowTipInCol = false
          if (v.pos[1] == -1 || v.pos[1] == col) {
            isShowTipInCol = true
          }

          return isShowTipInRow && isShowTipInCol
        }
      )
      if (tip) {
        ret = tip.text
      }
      return ret
    },
    handleCellClick(row) {
      this.$emit("cellClick", row);
    }
    ,
    handleToggleDetailCLick() {
      this.$emit("update:showDetails", !this.showDetails);
    }
    ,

  },
}
;
</script>

<style lang="stylus" scoped>

.drawer-inner {
  overflow-y auto;
  padding: 25px;
  height calc(100vh - 70px);
  padding-top 0;
  box-sizing: border-box
}


.text-has-tip {
  text-decoration underline;
  cursor pointer
}

.text-has-table {
  color blue
  cursor pointer
  text-decoration underline;
}
.table-wrapper
  min-width 100%;
  overflow scroll
.table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 22px;
  td, th {
    min-width 30px
    text-align: left;
    font-size: 12px;
    padding: 5px 2px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}

>>> :focus {
  outline: none;
}
</style>
